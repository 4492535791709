import React from 'react';
import './gomobile-styles.css';

export default class GoMobile extends React.Component {
    render(){
        return (
            <div id='goMobileContainer'>
                <h1 id='goMobileText'>Currently, this site is mobile only.</h1>
            </div>

            )
    }
}