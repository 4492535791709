import React from 'react';

import './footer-styles.css';

export default class Footer extends React.Component {
    render(){
        return(
            <div id='footer'></div>
        )
    }
}