import React from 'react';

import Home from '../Home/home';

export default class Main extends React.Component {
    
    render() {
        return(
            <Home/>
        )
    }
}